<template>
    <div>
        <img class="example-headImg" src="../../assets/image/productCase/banner-technology-1.png"/>
        <div class="head-explainBox" style="height: auto; min-height: 15.3rem;">
            <div class="head-explainBox-title1">IT 服务门户</div>
            <div class="head-explainBox-content1">某知名传媒科技公司</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem" @click="goAssignBlock('block'+ 0,50)"><span class="title">业务痛点</span></div>
            <div class="head-list-item" @click="goAssignBlock('block'+ 1,50)"><span class="title">实施方案</span></div>
            <div class="head-list-item" @click="goAssignBlock('block'+ 2,50)"><span class="title">实施收益</span></div>
        </div>
        <div class="container-1" style="display:flex" ref="block0">
            <div class="purchase-content-left">
                <div class="purchase-title">业务痛点</div>
            </div>
            <div class="purchase-content-right">
                <div class="selected-box">
                    <div class="selected-card">
                        <div class="title1">多业务场景</div>
                        <div class="content">既需要现场运维录单，又有研发管理需求，多个业务系统之间需要相互隔离。</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">缺乏统一视角</div>
                        <div class="content">研发团队专注于技术实现，运维团队负责系统稳定性，而项目管理团队则关注进度和资源分配，三者分而治之，没有统一的管理看板导致各团队的效率常常受到其他团队的掣肘。</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">信息孤岛</div>
                        <div class="content">常常遇到孤岛式的研发、运维和项目管理，导致团队间信息不畅，进而影响整个研发流程的效率和成果质量。</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 60rem;" ref="block1">
            <div class="purchase-content-left">
                <div class="purchase-title">实施方案</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-box">
                    <div class="card">
                        <img class="logo" src="../../assets/image/productCase/icon-technology-1.svg"/>
                        <div>
                            <div class="title1">私有化部署</div>
                            <div class="title2">多应用私有化部署，其业务相互完全隔离。</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/productCase/icon-technology-2.svg"/>
                        <div>
                            <div class="title1">应用内协作</div>
                            <div class="title2">打点对话、在线文档和内部沟通等多种功能，将该企业研发、运维和项目团队紧密地联系在一起，提升了该企业内部团队的协作效率。</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/productCase/icon-technology-3.svg"/>
                        <div>
                            <div class="title1">看板仪表盘视角</div>
                            <div class="title2">项目经理实时掌握团队的工作动态，及时调整资源分配和进度计划，确保项目的顺利进行。</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/productCase/icon-technology-4.svg"/>
                        <div>
                            <div class="title1">自动生成纪要及任务</div>
                            <div class="title2">通过自动生成会议纪要及任务，大幅降低录入误差。会议中确定的工作内容可以自动转为任务，确保会议决策能及时落地。</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-1" style="display:flex;margin-bottom:10rem" ref="block2">
            <div class="purchase-content-left">
                <div class="purchase-title">实施收益</div>
            </div>
            <div class="purchase-content-right">
              <div class="purchase-title purchase-content-item" style="display:flex">
                <div class="purchase-doit">●</div>
                <div class="purchase-title-label">提升了研发、项目和运维团队协作效率。</div>
              </div>
              <div class="purchase-title  purchase-content-item" style="display:flex">
                <div class="purchase-doit">●</div>
                <div class="purchase-title-label">实现了跨团队、多项目的并行运作，并确保了所有项目均按期交付。</div>
              </div>
              <div class="purchase-title  purchase-content-item" style="display:flex">
                <div class="purchase-doit">●</div>
                <div class="purchase-title-label">全面记录会议内容，对待办事项完整跟踪，最终高效转化为工作成果。</div>
              </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesproductCaseTechnology',
    metaInfo:{
        meta:[
        { name:'keywords', content:'IT 服务门户 - 某知名传媒科技公司' },
        { name:'description', content:'帮助企业以更低成本更好效果更短时间内实现备份' }
        ]
    },
    data() {
        return {
            navOffsetTop: 0,
        };
    },
    mounted() {
        this.activeIndex = '3'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
        // 监听滚动事件
        window.addEventListener('scroll', this.fiexdNav)
        this.getData()
    },
    destroyed () {
        // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
        window.removeEventListener('scroll', this.fiexdNav)
    },
    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
        goAssignBlock(el, speed) {
        const navHeight = document.querySelector('.head-list').offsetHeight //导航栏的高度
        let _this = this;
        let windowH = window.innerHeight; //浏览器窗口高度
        let h = this.$refs[el].offsetHeight; //模块内容高度
        let t = this.$refs[el].offsetTop - navHeight - speed; //模块相对于内容顶部的距离
        let top = t ; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
        let scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop; //滚动条距离顶部高度
        let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
        let requestId;
        //采用requestAnimationFrame，平滑动画
        function step() {
            //判断让滚动条向上滚还是向下滚
            if (scrollTop < top) {
                if (currentTop <= top) {
                    //   window.scrollTo(x,y) y为上下滚动位置
                    window.scrollTo(0, currentTop);
                    requestId = window.requestAnimationFrame(step);
                } else {
                    window.cancelAnimationFrame(requestId);
                }
                //向下滚动
                currentTop += speed;
            } else {
                if (top <= currentTop) {
                    //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
                    window.scrollTo(0, currentTop);
                    requestId = window.requestAnimationFrame(step);
                } else {
                    window.cancelAnimationFrame(requestId);
                }
                //向上滚动
                currentTop -= speed;
            }
        }
        window.requestAnimationFrame(step);
        },
        /** 设置导航条nav到达页面顶部时固定 **/
        // 1.获取导航条nav的offsetTop值，存储在data中（注：之所以不放在滚动事件中，是为了以防添加固定样式后offsetTop值为零,导致页面需要滚动到最上面才可以回到原位）
        getData () {
            this.navOffsetTop = document.querySelector('.head-list').offsetTop + 60
            console.log('this.navOffsetTop',this.navOffsetTop)
        },
        fiexdNav () {
            const navHeight = document.querySelector('.head-list').offsetHeight //导航栏的高度
            // 2.获取当前页面的卷曲高度
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            const nav = document.querySelector('.head-list')
            // 3.判断卷曲高度是否大于等于导航条的offsetTop值
            if (scrollTop > this.navOffsetTop) {
                //   3.1若满足，则给nav导航添加固定样式
                nav.classList.add('fixedNav')
            } else {
                //   3.2若不满足，则删除nav导航的固定样式
                nav.classList.remove('fixedNav')
            }

            /** 当滚动到一定区域时给导航项添加选中样式 **/
            //  1.获取所有锚点元素
            // 2.获取锚点元素的offsetTop值，并收集在一个数组
            const contentsOffsetTop = []
            // contents.forEach(item => {
            //     contentsOffsetTop.push(item.offsetTop)
            //     console.log('2222',item.offsetTop)
            // })
            contentsOffsetTop.push(this.$refs['block0'].offsetTop)
            contentsOffsetTop.push(this.$refs['block1'].offsetTop)
            contentsOffsetTop.push(this.$refs['block2'].offsetTop)
            // console.log('contentsOffsetTop',contentsOffsetTop)
            // console.log('contentsOffsetTop',contentsOffsetTop)
            // 3.获取页面高度
            const pageHeight = window.innerHeight
            // 4.获取nav的子元素
            const navChildren = document.querySelectorAll('.head-list .head-list-item')
            for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[0].classList.add('head-list-item-ischeck')
                }
            if(scrollTop>contentsOffsetTop[0] + this.$refs['block0'].offsetHeight/2){
                for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[1].classList.add('head-list-item-ischeck')
                }
            }
            if(scrollTop>contentsOffsetTop[1] + this.$refs['block1'].offsetHeight/2){
                for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[2].classList.add('head-list-item-ischeck')
                }
            }
        },
        /**
         *设置点击导航跳转到指定选择器对应的锚点元素
        * @param {*} selector
        **/
        skipTo (selector) {
            const navHeight = document.querySelector('.head-list-item').offsetHeight
            // scrollIntoView() js原生方法，实现锚点滚动过渡
            const target = document.querySelector(selector)
            target.scrollIntoView({ behavior: 'smooth' })
            // scrollTo() 把内容滚动到指定的坐标。减去导航高度的目的：导航用定位固定在顶部，如果不减去，导航栏会遮挡一部分内容
            window.scrollTo(0, target.offsetTop - navHeight)
        }
    },
};
</script>

<style lang="less" scoped>
.selected-titlecheck{
    width: 106.8rem;
    display: flex;
    text-align: center;
    // border: 1px solid #f00;
    .selected-contain1{
        display: flex;
        margin: auto;
    }
    .title1{
        width: 8.4rem;
        height: 2rem;
        font-size: 2rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
        opacity: 0.2;

        margin-bottom: 4.8rem;
        margin-top: 2.8rem;
    }
    .title2{
        position: absolute;
        width: 4rem;
        height: 4rem;
        font-size: 2rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #005395;
        line-height: 2.8rem;
        margin: 1.9rem 0 0 3rem;
        cursor: pointer;
        .line{
            width: 4rem;
            height: 2rem;
            border-bottom: 3px solid #005395;
            border-radius: 50%;
            margin: -1rem 0 0 0rem;
        }
    }
}
.purchase-content-left{
    width:33rem;
    text-align: justify;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-doit{
    color: #005395;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
.purchase-content-item {
  &:not(:last-child) {
    margin-bottom: 8rem;
  }
}
.purchase-title-label {
  font-size: 1.6rem;
  font-family: 'CN_Regular';
  font-weight: 400;
  color: #666666;
  line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: justify;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 106.8rem;
    height: 71.7rem;
}
.solution-img2{
    width: 107rem;
    height: 41.7rem;
    margin-top: 5rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
.selected-box{
    width: 106.8rem;
    display: flex;
    margin-bottom: 5rem;
    .selected-card{
        width: 35.6rem;
        min-height: 12rem;
        padding: 2rem;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        display: flex;
        flex-direction: column;
        .title1{
            text-align: left;
            width: 29.2rem;
            font-size: 2rem;
            font-family: 'CN_Regular';
            font-weight: 500;
            color: #333333;
            line-height: 3rem;
        }
        .content {
          margin-top: 1.8rem;
          font-weight: 400;
          font-size: 1.6rem;
          color: #666666;
          line-height: 2.4rem;
        }
    }
    .selected-card:hover{
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
        border: 1px solid #005395;
    }
}
.purchase-box{
    width: 106rem;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;

    .card{
        width: 52.8rem;
        height: 18rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 2rem 0 0 2.1rem;
        }
        .title2{
            width: 43.2rem;
            height: 7.2rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;

            margin: 1.5rem 0 0 2.1rem;
        }
    }
}
</style>
